

// export const PHONE_TEMPLE_BASE_URL_API = "http://localhost/admin-panel/api/mailer/";

// staging
// export const PHONE_TEMPLE_BASE_URL_API = "https://admin.phonetemple.site/api/mailer/";
// export const PHONE_TEMPLE_BASE_URL_IMG = "https://admin.phonetemple.site/"
// export const PHONE_TEMPLE_BASE_URL = "https://admin.phonetemple.site";


// production
export const PHONE_TEMPLE_BASE_URL_API = "https://templeoracle.site/api/mailer/";
export const PHONE_TEMPLE_BASE_URL_IMG = "https://templeoracle.site/";
export const PHONE_TEMPLE_BASE_URL = "https://templeoracle.site";


// purrfectoracle production
// export const PHONE_TEMPLE_BASE_URL_API = "https://purrfectoracle.site/api/mailer/";
// export const PHONE_TEMPLE_BASE_URL_IMG = "https://purrfectoracle.site/";
// export const PHONE_TEMPLE_BASE_URL = "https://purrfectoracle.site";


// local
// export const PHONE_TEMPLE_BASE_URL_API = "http://localhost/templeoracle/api/mailer/";
// export const PHONE_TEMPLE_BASE_URL_IMG = "http://localhost/templeoracle/";
// export const PHONE_TEMPLE_BASE_URL = "http://localhost/templeoracle";



// asym local
// export const PHONE_TEMPLE_BASE_URL_API = "http://templeoracle.local/templeoracle/api/mailer/";
// export const PHONE_TEMPLE_BASE_URL_IMG = "http://templeoracle.local/templeoracle/";
// export const PHONE_TEMPLE_BASE_URL = "http://templeoracle.local/templeoracle";